@tailwind base;
@tailwind components;

@layer base {
@font-face {
    font-family: 'TeleNeo-Bold';
    src: url('./fonts/TeleNeo-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-BoldItalic';
    src: url('./fonts/TeleNeo-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-ExtraBold';
    src:url('./fonts/TeleNeo-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-ExtraBoldItalic';
    src:url('./fonts/TeleNeo-ExtraBoldItalic.otf') format('opentype');
}


@font-face {
    font-family: 'TeleNeo-Medium';
    src: url('./fonts/TeleNeo-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-MediumItalic';
    src: url('./fonts/TeleNeo-MediumItalic.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-Regular';
    src:url('./fonts/TeleNeo-Regular.otf') format('opentype');
}


@font-face {
    font-family: 'TeleNeo-RegularItalic';
    src:url('./fonts/TeleNeo-RegularItalic.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-Thin';
    src: url('./fonts/TeleNeo-Thin.otf') format('opentype');
}

@font-face {
    font-family: 'TeleNeo-ThinItalic';
    src: url('./fonts/TeleNeo-ThinItalic.otf') format('opentype');
}
p.carousel-status {
    opacity:0;
}

ul.control-dots {
    opacity:0;
}

li.carousel .thumb.selected, li.carousel .thumb:hover
 {
    border: 0px solid white !important;
}

.carousel.carousel-slider .control-arrow:hover {
    
}
.slide { 

}
.slide div {

}

.slide div img { 

}


}

@tailwind utilities;